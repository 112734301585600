<template>
  <div hidden></div>
</template>

<script>

export default {
  name: 'Payment',
  mounted() {
    const error = this.$route.query.error;

    if (error) {
      this.$router.push('/payment/failure');
    } else {
      this.$router.push('/payment/success');
    }
  },
  methods: {}
};
</script>

<style scoped>
</style>
